@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MLight";
  src: url('./assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: "MRegular";
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: "MMedium";
  src: url('./assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: "MBold";
  src: url('./assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: "MBlack";
  src: url('./assets/fonts/Montserrat-Black.ttf');
}

*{
  font-family: "MLight";
}